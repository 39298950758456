enum Connection {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createNotification = (title: string, type: Connection) => {
  const styles = `position: fixed; bottom: 0; left: 0; width: 100%; background: ${
    type === Connection.OFFLINE ? '#eb5757' : '#DFF0D8'
  }; color: ${
    type === Connection.OFFLINE ? 'white' : '#3c763d'
  }; text-align: center; padding: 20px; z-index: 1`;

  const stylesText = `margin: 0;`;

  const html = `
    <div id="notification_${type}" style="${styles}">
      <h3 style="${stylesText}">${title}</h3>
    </div>
  `;

  const body = document.querySelector('body');
  if (body) body.insertAdjacentHTML('beforeend', html);
};

const removeNotification = (type: Connection) => {
  const notification = document.querySelector(`#notification_${type}`);
  if (notification) {
    notification.remove();
  }
};

window.addEventListener('online', async () => {
  removeNotification(Connection.OFFLINE);
  createNotification('You are back online', Connection.ONLINE);
  await sleep(3000);
  removeNotification(Connection.ONLINE);
});

window.addEventListener('offline', () => {
  removeNotification(Connection.ONLINE);
  createNotification('You are offline', Connection.OFFLINE);
});
